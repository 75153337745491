














































import { Component, Vue } from "vue-property-decorator";
import { mdiMenu } from "@mdi/js";
import { IconsType } from "@/types";

@Component
export default class MenuNav extends Vue {
  private icons: IconsType = {
    menu: mdiMenu
  };
  get selectedCategory() {
    if (this.selected)
      return this.$store.state.CategoriesModule.categories[this.selected].name;
    else return this.$store.state.CategoriesModule.categories[0].name;
  }
  get selected(): number {
    return this.$store.state.CategoriesModule.selected;
  }
  set selected(value: number) {
    this.$store.commit("CategoriesModule/SELECT", value);
  }
}
